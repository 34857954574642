import React, { useEffect } from 'react';

import firebase from '../firebase';
import { BROADCAST_DETAIL_PATH_REGEX, BROADCAST_ACCESS_TOKEN_KEY, SIGN_IN_TOKEN_KEY, PATHNAMES } from '../utils/constants';
import { authRedirect } from '../utils/authHelpers';
import { NTSLogoIcon } from './Icons';

import Loader from './Loader';

function AuthHandler({ pathname }) {
    useEffect(() => {
        if (pathname === PATHNAMES.logout) return;

        const broadcastAccessToken = new URLSearchParams(window.location.search).get(BROADCAST_ACCESS_TOKEN_KEY);
        const signInToken = new URLSearchParams(window.location.search).get(SIGN_IN_TOKEN_KEY);

        if (BROADCAST_DETAIL_PATH_REGEX.test(pathname) && broadcastAccessToken) {
            const broadcastId = pathname.match(BROADCAST_DETAIL_PATH_REGEX)[1];
            firebase.signInWithBroadcastAccessToken(broadcastAccessToken, broadcastId);
        } else if (signInToken) {
            firebase.signInWithToken(signInToken);
        } else {
            authRedirect(window.location.pathname);
        }
    }, []);

    if (pathname === PATHNAMES.logout) return (
        <div className="app-loader">
            <div className="auth-modal">
                <div className="auth-modal__header">
                    <NTSLogoIcon />
                    <h1>Desk</h1>
                </div>
                <div>
                    Log back in to access NTS Desk.
                </div>
                <div className="auth-modal__login">
                    <button onClick={() => authRedirect()} className="mdl-button mdl-button--raised">
                        Log in with My NTS
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="app-loader">
            <Loader />
        </div>
    );
}

export default AuthHandler;
