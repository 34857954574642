import React, { useEffect, useState } from 'react';

import { isAuthorised } from '../utils/authHelpers';
import { MODALS } from '../utils/constants';
import { renderAdminPage, renderHostPage } from '../utils/renderHelpers';
import AccountEditModal from '../components/AccountEditModal';
import UnauthorisedView from './UnauthorisedView';
import firebase from '../firebase';
import HostEditModal from '../components/HostEditModal';
import Loader from './Loader';
import NavBar from './NavBar';
import PayoutModal from '../components/PayoutModal';
import AuthHandler from './AuthHandler';
import SubscriptionLinkModal from './SubscriptionLinkModal';
import AccountDeleteModal from './AccountDeleteModal';
import CreateBroadcastModal from './CreateBroadcastModal';
import BroadcastLinkModal from './BroadcastLinkModal';
import BroadcastImageModal from './BroadcastImageModal';
import ConfirmationModal from './ConfirmationModal';
import BroadcastAudioModal from './BroadcastAudioModal';
import BroadcastNoteModal from './BroadcastNoteModal';
import BroadcastNotifyModal from './BroadcastNotifyModal';

import '../scss/styles.scss';

function App() {
    const [loading, setLoading] = useState(true);
    const [authUser, setAuthUser] = useState(null);
    const [pathname, setPathname] = useState('/');
    const [customerEmail, setCustomerEmail] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [authUserShows, setAuthUserShows] = useState([]);
    const [showAliasSelected, setShowAliasSelected] = useState(null);
    const [modalOpen, setModalOpen] = useState(null);
    const [broadcastList, setBroadcastList] = useState([]);
    const [selectedBroadcast, setSelectedBroadcast] = useState(null);
    const [accessLink, setAccessLink] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);

    function onPopstate() {
        setPathname(window.location.pathname);
    }

    async function getUserBroadcasts() {
        if (!authUser) return setBroadcastList([]);

        if (authUser.roles.admin) {
            return firebase.getRecentBroadcasts()
                .then(setBroadcastList)
                .catch(err => alert(`Something went wrong. ${err.message}`));
        }

        return firebase.getAuthUserBroadcasts()
            .then(setBroadcastList)
            .catch(err => {
                alert(`Something went wrong. ${err.message}`);
            });
    }

    async function getHostShows() {
        if (!authUser) return setAuthUserShows([]);
        if (!authUser.roles.host) return setAuthUserShows([]);

        return firebase.getHostShows()
            .then(hostShows => {
                setAuthUserShows(hostShows);
            })
            .catch(err => {
                alert(`Something went wrong. ${err.message}`);
            });
    }

    function getBroadcastDetails(broadcastId) {
        const broadcastIds = broadcastList.map(broadcast => broadcast.id);
        if (!broadcastId || !broadcastIds.includes(broadcastId)) return;

        return firebase.getBroadcastDetails(broadcastId)
            .then(setSelectedBroadcast)
            .catch(err => {
                alert(`Something went wrong when fetching broadcast details. ${err.message}`);
            });
    }

    async function deleteBroadcastImage() {
        const broadcastId = selectedBroadcast.id;
        await firebase.deleteBroadcastImage({
            broadcastId,
            image: selectedImage
        });
        setSelectedImage(null);

        return getBroadcastDetails(broadcastId);
    }

    async function deleteBroadcastAudio() {
        const broadcastId = selectedBroadcast.id;
        await firebase.deleteBroadcastAudio({
            broadcastId,
            audio: selectedAudio
        });
        setSelectedAudio(null);

        return getBroadcastDetails(broadcastId);
    }

    async function getUserData() {
        if (!authUser) return setLoading(false);

        setLoading(true);
        await getUserBroadcasts();
        await getHostShows();
        setLoading(false);
    }

    useEffect(() => {
        const unlisten = firebase.onAuthStateChanged(user => {
            setAuthUser(user);
        });
        return unlisten;
    }, []);

    useEffect(() => {
        onPopstate();
        window.addEventListener('popstate', onPopstate);
        return () => window.removeEventListener('popstate', onPopstate);
    }, []);

    useEffect(() => {
        getUserData();
    }, [authUser]);

    if (loading) return (
        <div className="app-loader">
            <Loader />
        </div>
    );

    if (!authUser) return <AuthHandler pathname={pathname} />;

    const { email, roles } = authUser;
    if (!isAuthorised(roles, pathname)) return <UnauthorisedView authUser={authUser} setPathname={setPathname} />;

    return (
        <div className="app mdl-layout__container">
            <div className="mdl-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
                <NavBar
                    authUser={authUser}
                    pathname={pathname}
                    setPathname={setPathname}
                    broadcastList={broadcastList}
                    authUserShows={authUserShows} />
                <main className="mdl-layout__content">
                    <div className="app__content">
                        <div className="app__content-wrapper">
                            {renderAdminPage(roles, pathname, {
                                searchResult,
                                setSearchResult,
                                customerEmail,
                                setCustomerEmail,
                                setModalOpen,
                                broadcastList,
                                setPathname,
                                getUserBroadcasts,
                                setAccessLink,
                                getBroadcastDetails,
                                selectedBroadcast,
                                setSelectedImage,
                                setSelectedAudio,
                            })}
                            {renderHostPage(roles, pathname, {
                                authUserShows,
                                email,
                                getHostShows,
                                setModalOpen,
                                setShowAliasSelected,
                                broadcastList,
                                setPathname,
                                getUserBroadcasts,
                                getBroadcastDetails,
                                selectedBroadcast,
                                setSelectedImage,
                                setSelectedAudio,
                            })}
                        </div>
                    </div>
                </main>
                {modalOpen === MODALS.hostEdit
                    && <HostEditModal
                        searchResult={searchResult}
                        setModalOpen={setModalOpen}
                        setSearchResult={setSearchResult} />}
                {modalOpen === MODALS.accountEdit
                    && <AccountEditModal
                        pathname={pathname}
                        searchResult={searchResult}
                        setModalOpen={setModalOpen}
                        setSearchResult={setSearchResult}
                        setCustomerEmail={setCustomerEmail} />}
                {modalOpen === MODALS.payout
                    && <PayoutModal
                        authUserShows={authUserShows}
                        setAuthUserShows={setAuthUserShows}
                        setModalOpen={setModalOpen}
                        showAliasSelected={showAliasSelected} />}
                {modalOpen === MODALS.subscriptionLink
                    && <SubscriptionLinkModal
                        pathname={pathname}
                        setSearchResult={setSearchResult}
                        searchResult={searchResult}
                        setModalOpen={setModalOpen} />}
                {modalOpen === MODALS.accountDelete
                    && <AccountDeleteModal
                        pathname={pathname}
                        setSearchResult={setSearchResult}
                        searchResult={searchResult}
                        setModalOpen={setModalOpen} />}
                {modalOpen === MODALS.createBroadcast
                    && <CreateBroadcastModal
                        setModalOpen={setModalOpen}
                        setPathname={setPathname}
                        getUserBroadcasts={getUserBroadcasts}
                        setAccessLink={setAccessLink}
                        authUser={authUser} />}
                {modalOpen === MODALS.broadcastAccessLink
                    && <BroadcastLinkModal
                        selectedBroadcast={selectedBroadcast}
                        setModalOpen={setModalOpen}
                        accessLink={accessLink}
                        setAccessLink={setAccessLink}
                        getBroadcastDetails={getBroadcastDetails} />}
                {modalOpen === MODALS.broadcastImage
                    && <BroadcastImageModal
                        selectedBroadcast={selectedBroadcast}
                        setModalOpen={setModalOpen}
                        getBroadcastDetails={getBroadcastDetails} />}
                {modalOpen === MODALS.deleteBroadcastImage
                    && <ConfirmationModal
                        setModalOpen={setModalOpen}
                        confirmationAction={deleteBroadcastImage}
                        ctaText="Delete" />}
                {modalOpen === MODALS.broadcastAudio
                    && <BroadcastAudioModal
                        selectedBroadcast={selectedBroadcast}
                        setModalOpen={setModalOpen}
                        getBroadcastDetails={getBroadcastDetails} />}
                {modalOpen === MODALS.deleteBroadcastAudio
                    && <ConfirmationModal
                        setModalOpen={setModalOpen}
                        confirmationAction={deleteBroadcastAudio}
                        ctaText="Delete" />}
                {modalOpen === MODALS.broadcastNote
                    && <BroadcastNoteModal
                        selectedBroadcast={selectedBroadcast}
                        setModalOpen={setModalOpen}
                        getBroadcastDetails={getBroadcastDetails} />}
                {modalOpen === MODALS.broadcastNotify
                    && <BroadcastNotifyModal
                        setModalOpen={setModalOpen}/>}
            </div>
        </div>
    );
}

export default App;
