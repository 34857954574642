import React from 'react';

function BroadcastNotifyModal({ setModalOpen }) {
    function onCancelClick() {
        setModalOpen(null);
    }
    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="modal-title">
                        Thank you
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        A note has been created to let the NTS team know that your broadcast is ready for review.
                    </div>
                    <div className="edit-modal__buttons">
                        <button
                            className="mdl-button mdl-button--raised button-responsive"
                            name="cancel"
                            type="button"
                            onClick={onCancelClick}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BroadcastNotifyModal;
