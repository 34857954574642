class Roles {
    constructor(admin, host) {
        this.admin = admin;
        this.host = host;
    }
}

class AuthUser {
    constructor(firebaseUser, roles) {
        this.email = firebaseUser.email;
        this.roles = roles;
    }
}

class Broadcast {
    constructor(broadcastId, broadcastData, userEmails, broadcastImages, broadcastAudios) {
        this.id = broadcastId;

        this.title = broadcastData.title || '';
        this.location = broadcastData.location || '';
        this.tracklist = broadcastData.tracklist || '';
        this.description = broadcastData.description || '';
        this.notes = (broadcastData.notes || []).map(note => {
            return {content: note.content, createdBy: note.created_by};
        });

        this.userEmails = userEmails || [];
        this.images = broadcastImages ||
            (broadcastData.images || []).map(imageData => new BroadcastImage(imageData));
        this.audios = broadcastAudios ||
            (broadcastData.audio_filepaths || []).map(filepath => new BroadcastAudio(filepath));

    }
    get hasAudio() {
        return !!this.audios.length;
    }
    get hasImage() {
        return !!this.images.length;
    }
    get hasTracklist () {
        return !!this.tracklist;
    }
}
class BroadcastAudio {
    constructor(filepath, url) {
        this.filepath = filepath;
        this.url = url || '';
    }
}
class BroadcastImage {
    constructor(image, url) {
        this.author = image.author;
        this.title = image.title;
        this.source = image.source;
        this.filepath = image.filepath;
        this.use = image.use;
        this.url = url || '';
    }
}

function payoutDate(timeStamp) {
    if(!timeStamp) return null;

    const cutoffDate = new Date(timeStamp);
    const threeDaysMs = 3 * 24 * 3600 * 1000;
    return new Date(cutoffDate.getTime() + threeDaysMs);
}

class HostShow {
    constructor(hostShowData) {
        this.showAlias = hostShowData.showAlias;
        this.name = hostShowData.name;
        this.hours = hostShowData.hours;
        this.hoursConfirmed = hostShowData.hoursConfirmed;
        this.amountCents = hostShowData.amountCents;
        this.payoutConfirmed = hostShowData.payoutConfirmed;
        this.invoiceUrl = hostShowData.invoiceUrl;
        this.payoutAt = payoutDate(hostShowData.payoutAt);
    }
    get isPayoutPhase() {
        return this.amountCents > 0;
    }
    get isConfirmed() {
        return !this.isPayoutPhase && this.hoursConfirmed === this.hours
            || this.isPayoutPhase && !!this.payoutConfirmed;
    }
    get donationIsConfirmed() {
        return this.payoutConfirmed === 'donate';
    }
}

export {
    AuthUser,
    Broadcast,
    BroadcastAudio,
    BroadcastImage,
    HostShow,
    Roles,
};
