import React from 'react';

import { MODALS } from '../utils/constants';

function BroadcastNotes({ notes, setModalOpen }) {
    return (
        <div className="card">
            <div className="card__account-header">
                <h2 className="card__title">Notes</h2>
                <div>
                    <button
                        className="mdl-button mdl-button--raised button-blue"
                        onClick={() => setModalOpen(MODALS.broadcastNote)}
                        style={{ minWidth: '150px', float: 'right', marginBottom: '12px' }}>
                        Add note
                    </button>
                </div>
            </div>
            {notes.map((note, i) => (
                <div className="card"
                    key={`${note.content.slice(0, 5)}-${i}`}>
                    <div>
                        {note.content}
                    </div>
                    <br />
                    <div>
                        {note.created_by}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default BroadcastNotes;
