import React, { useState } from 'react';

import firebase from '../firebase';
import { PATHNAMES, MODALS } from '../utils/constants';

const FIELDS = {
    email: 'email',
    title: 'title'
};

function CreateBroadcastModal({ setModalOpen, setPathname, getUserBroadcasts, setAccessLink, authUser }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [broadcastEmail, setBroadcastEmail] = useState('');
    const [broadcastTitle, setBroadcastTitle] = useState('');
    const isAdmin = authUser.roles && authUser.roles.admin;

    function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);

        const email = isAdmin ? broadcastEmail : authUser.email;
        firebase.createBroadcast(email, broadcastTitle)
            .then(async ({ broadcastAccessLink, broadcastId }) => {
                await getUserBroadcasts();
                if (!broadcastAccessLink) {
                    const href = [PATHNAMES.broadcasts, broadcastId].join("/");
                    setPathname(href);
                    window.history.pushState({}, '', href);
                    return setModalOpen(null);
                }

                setAccessLink(broadcastAccessLink);
                setModalOpen(MODALS.broadcastAccessLink);
                setIsSubmitting(false);
            })
            .catch(err => alert(`Something went wrong. ${err.message || ''}`));
    }

    function onCancelClick() {
        setModalOpen(null);
    }

    function handleChange(e) {
        const { value, name } = e.target;
        switch(name) {
            case FIELDS.email:
                setBroadcastEmail(value);
                break;
            case FIELDS.title:
                setBroadcastTitle(value);
                break;
        }
    }

    return (
        <div>
            <div className="full-page-overlay" onClick={onCancelClick} />
            <div className="modal-container">
                <div className="modal-container__modal edit-modal">
                    <div className="modal-title">
                        Add incoming broadcast
                    </div>
                    <div>
                        Please enter a suitable title that will help the NTS team to identify your show, eg. name of the artist.
                        <br />
                        You will be able to edit these details at any time.
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mdl-textfield" style={{ width: '100%' }}>
                            <label className="mdl-typography--body-1" htmlFor="title">
                                Broadcast title
                            </label>
                            <input
                                id="title"
                                required
                                className="mdl-textfield__input edit-modal__input"
                                type="text"
                                name={FIELDS.title}
                                value={broadcastTitle}
                                onChange={handleChange} />
                        </div>
                        {isAdmin && (
                            <div className="mdl-textfield" style={{ width: '100%' }}>
                                <label className="mdl-typography--body-1" htmlFor="email">
                                    Email address
                                </label>
                                <input
                                    id="email"
                                    className="mdl-textfield__input edit-modal__input"
                                    type="email"
                                    name={FIELDS.email}
                                    value={broadcastEmail}
                                    onChange={handleChange} />
                            </div>
                        )}
                        <div className="edit-modal__buttons">
                            <button
                                className="mdl-button mdl-button--raised button-responsive"
                                name="cancel"
                                type="button"
                                onClick={onCancelClick}
                                disabled={isSubmitting}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mdl-button mdl-button--raised button-blue button-responsive"
                                name="save"
                                disabled={isSubmitting}>
                                Create
                                {isSubmitting &&
                                    <i className="material-icons rotating" role="presentation">
                                        rotate_right
                                    </i>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateBroadcastModal;
