import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import { AuthUser, HostShow, Roles } from './utils/models';
import { PATHNAMES } from './utils/constants';
import { isProduction } from './utils/environmentHelpers';

const BROADCAST_LIMIT = 100;
const FIRESTORE_OR_LIMIT = 30;
const BROADCAST_IMAGES_EXPORT_PATH = 'broadcast_images';
const BROADCAST_AUDIO_EXPORT_PATH = 'broadcast_audio';

function expand(pathname) {
    const value = [ 'userEmails' ];
    switch(pathname) {
        case PATHNAMES.home:
            value.push('subs');
            break;
        case PATHNAMES.devices:
            value.push('deviceIds', 'deviceSettings');
            break;
        case PATHNAMES.recommendations:
            value.push('recommendations');
            break;
        case PATHNAMES.hosts:
            value.push('roles', 'shows');
            break;
    }
    return value;
}

function getFirebaseConfig() {
    if (!isProduction()) return {
        apiKey: "AIzaSyD8S9R9btaGGZLJ0bLoRgIgjrv9p4YxoQc",
        authDomain: 'nts-users-int.firebaseapp.com',
        databaseURL: 'https://nts-users-int.firebaseio.com',
        projectId: "nts-users-int",
        storageBucket: "nts-users-int.appspot.com",
    };

    return {
        apiKey: 'AIzaSyA4Qp5AvHC8Rev72-10-_DY614w_bxUCJU',
        authDomain: 'nts-ios-app.firebaseapp.com',
        databaseURL: 'https://nts-ios-app.firebaseio.com',
        projectId: "nts-ios-app",
        storageBucket: "nts-ios-app.appspot.com",
    };
}

function broadcastAccessLink(id, token) {
    if (!id || !token) return null;

    return `${window.location.origin}${PATHNAMES.broadcasts}/${id}?token=${token}`;
}

function constructBroadcastFilepath(broadcastId, file, exportPath) {
    const fileId = crypto.randomUUID();
    const extension = file.name.split('.').pop();
    return `${exportPath}/${broadcastId}-${fileId}.${extension}`;
}

class Firebase {
    constructor() {
        const app = firebase.initializeApp(getFirebaseConfig());
        this.firestore = app.firestore();
        this.functions = app.functions('europe-west2');
        this.auth = firebase.auth();
        this.getHostShows = this.getHostShows.bind(this);
        this.storage = app.storage();
    }

    signIn(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    signOut() {
        return this.auth.signOut();
    }

    getRoles(currentUser) {
        return currentUser.getIdTokenResult()
            .then(idTokenResult => {
                if (!idTokenResult.claims) return new Roles(false, false);

                return new Roles(
                    idTokenResult.claims.admin,
                    idTokenResult.claims.host
                );
            });
    }

    getCustomerInfo(customerEmail, pathname) {
        const data = {
            customerEmail,
            expand: expand(pathname),
        };
        const getCustomerInfo = this.functions.httpsCallable('getCustomerInfoEurope');
        return getCustomerInfo(data);
    }

    updateCustomerEmail(customerEmail, newEmail) {
        const data = {
            customerEmail,
            newEmail,
        };
        const updateCustomerEmail = this.functions.httpsCallable('updateCustomerEmailAddressEurope');
        return updateCustomerEmail(data);
    }

    getDownloadHostsUrl() {
        const getUserToken = this.functions.httpsCallable('getUserTokenEurope');
        return getUserToken().then(resp => {
            const token = resp.data;
            const config = getFirebaseConfig();
            return `https://europe-west2-${config.projectId}.cloudfunctions.net/downloadHostsEurope?token=${token}`;
        });
    }

    onAuthStateChanged(callback) {
        return this.auth.onAuthStateChanged(firebaseUser => {
            if (!firebaseUser) return callback(null);

            this.getRoles(firebaseUser)
                .then(roles => {
                    callback(new AuthUser(firebaseUser, roles));
                });
        });
    }

    updateHostRole(customerEmail, isHost, shows) {
        const data = {
            customerEmail,
            isHost,
            shows,
        };
        const updateHostRole = this.functions.httpsCallable('updateHostRoleEurope');
        return updateHostRole(data);
    }

    getHostShows() {
        const getHostShows = this.functions.httpsCallable('getHostShowsEurope');
        return getHostShows()
            .then(response => {
                return response.data.map(hostShowData => new HostShow(hostShowData));
            });
    }

    confirmShowPayout(payoutDetails, showAlias, personalDetails, amountCents) {
        const data = {
            amountCents,
            showAlias,
            payoutDetails,
            personalDetails,
        };
        const confirmShowPayout = this.functions.httpsCallable('confirmShowPayoutEurope');
        return confirmShowPayout(data);
    }

    confirmShowHours(hours, showAlias) {
        const data = {
            hours,
            showAlias,
        };
        const confirmShowHours = this.functions.httpsCallable('confirmShowHoursEurope');
        return confirmShowHours(data);
    }

    linkSubscriptionEmail(firebaseUserUid, subscriptionEmail) {
        const linkSubscriptionEmail = this.functions.httpsCallable('linkSubscriptionEmailEurope');
        return linkSubscriptionEmail({ firebaseUserUid, subscriptionEmail });
    }

    deleteUser(firebaseUserUid) {
        const deleteUser = this.functions.httpsCallable('deleteUserEurope');
        return deleteUser({ firebaseUserUid });
    }

    getListenerPicks(reportMonth) {
        const getListenerPicks = this.functions.httpsCallable('getListenerPicksEurope');
        return getListenerPicks({ reportMonth });
    }

    getBroadcastAccessToken(email, broadcastId) {
        const getBroadcastAccessToken = this.functions.httpsCallable('getBroadcastAccessTokenEurope');
        return getBroadcastAccessToken({ email, broadcastId });
    }

    getSignInToken(ntsToken, broadcastId) {
        const getBroadcastAccessToken = this.functions.httpsCallable('getDeskAppSignInTokenEurope');
        return getBroadcastAccessToken({ ntsToken, broadcastId });
    }

    async getAuthUserBroadcasts() {
        const adminUserDoc = await this.firestore
            .collection('admin_users')
            .doc(this.auth.currentUser.uid)
            .get();
        let { broadcasts } = adminUserDoc.data();
        if (!broadcasts) return [];

        broadcasts = broadcasts.slice(0, FIRESTORE_OR_LIMIT);
        const querySnapshot = await this.firestore
            .collection('broadcasts')
            .where(firebase.firestore.FieldPath.documentId(), 'in', broadcasts)
            .limit(BROADCAST_LIMIT)
            .get();

        return querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                title: data.title || '',
            };
        });
    }

    async getAdminUserEmailsByBroadcastId(broadcastId) {
        const querySnapshot = await this.firestore
            .collection('admin_users')
            .where('broadcasts', 'array-contains', broadcastId)
            .get();
        if (querySnapshot.empty) return [];

        return querySnapshot.docs.reduce((emails, doc) => {
            const { user_email } = doc.data();
            if (user_email) emails.push(user_email);
            return emails;
        }, []);
    }

    async getBroadcastImages(images) {
        if (!images) return [];

        const imagesWithUrl = await Promise.all(
            images.map(async image => {
                let url = null;
                if (image.filepath) {
                    try {
                        url = await this.storage.ref().child(image.filepath).getDownloadURL();
                    } catch(e) {
                        console.log("Unable to fetch image url");
                    }
                }

                return {
                    author: image.author,
                    title: image.title,
                    source: image.source,
                    filepath: image.filepath,
                    url,
                };
            })
        );
        return imagesWithUrl.reverse();
    }

    async getBroadcastAudios(filepaths) {
        if (!filepaths) return [];

        const audioItems = await Promise.all(
            filepaths.map(async filepath => {
                const url = await this.storage.ref().child(filepath).getDownloadURL();
                return {
                    filepath,
                    url,
                };
            })
        );
        return audioItems.reverse();
    }

    async getBroadcastDetails(broadcastId) {
        const userEmails = await this.getAdminUserEmailsByBroadcastId(broadcastId);
        const broadcastDoc = await this.firestore
            .collection('broadcasts')
            .doc(broadcastId)
            .get();
        const broadcastData = broadcastDoc.data();
        const broadcastImages = await this.getBroadcastImages(broadcastData.images);
        const broadcastAudios = await this.getBroadcastAudios(broadcastData.audio_filepaths);
        return {
            id: broadcastId,
            title: broadcastData.title || '',
            userEmails,
            tracklist: broadcastData.tracklist || '',
            notes: broadcastData.notes || [],
            images: broadcastImages,
            audios: broadcastAudios,
        };
    }

    async getRecentBroadcasts() {
        const querySnapshot = await this.firestore
            .collection('broadcasts')
            .orderBy('created_at', 'desc')
            .limit(BROADCAST_LIMIT)
            .get();

        if (querySnapshot.empty) return [];

        return querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                title: data.title || '',
            };
        });
    }

    async writeBroadcast(title) {
        const broadcastDocRef = await this.firestore
            .collection('broadcasts')
            .add({
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
                title: title || '',
            });

        return broadcastDocRef.id;
    }

    updateBroadcast(broadcastId, data) {
        return this.firestore.collection('broadcasts').doc(broadcastId).update(data);
    }

    async getHostUserByEmail(email) {
        const querySnapshot = await this.firestore
            .collection('admin_users')
            .where('user_email', '==', email)
            .get();
        if (querySnapshot.empty) return null;

        const doc = querySnapshot.docs[0];
        const { host } = doc.data();
        if (!host) return null;

        return doc;
    }

    async createBroadcast(email, title) {
        if (!email) {
            await this.writeBroadcast(title);
            return null;
        }

        const newBroadcastId = await this.writeBroadcast(title);
        const hostUser = await this.getHostUserByEmail(email);
        if (hostUser) await hostUser.ref.update({
            broadcasts: firebase.firestore.FieldValue.arrayUnion(newBroadcastId),
        });

        const tokenResponse = await this.getBroadcastAccessToken(email, newBroadcastId);
        const token = tokenResponse && tokenResponse.data;
        return broadcastAccessLink(newBroadcastId, token);
    }

    async getBroadcastAccessLink(broadcastId, email) {
        const response = await this.getBroadcastAccessToken(email, broadcastId);
        const token = response.data;
        if (!token) return null;

        return broadcastAccessLink(broadcastId, token);
    }

    async signInWithToken(accessToken, broadcastId) {
        const response = await this.getSignInToken(accessToken, broadcastId);
        const signInToken = response.data;
        return this.auth.signInWithCustomToken(signInToken);
    }

    async uploadBroadcastImage({ broadcastId, imageTitle, imageAuthor, imageSource, imageFile }) {
        const filepath = constructBroadcastFilepath(broadcastId, imageFile, BROADCAST_IMAGES_EXPORT_PATH);
        const storageRef = this.storage.ref().child(filepath);
        await storageRef.put(imageFile);

        const image = {
            filepath,
            source: imageSource,
            author: imageAuthor,
            title: imageTitle
        };
        return this.firestore.collection('broadcasts').doc(broadcastId).update({
            images: firebase.firestore.FieldValue.arrayUnion(image),
        });
    }

    async deleteBroadcastImage({ broadcastId, image }) {
        const storageRef = this.storage.ref().child(image.filepath);
        await storageRef.delete();

        const broadcastImage = {
            filepath: image.filepath,
            source: image.source,
            author: image.author,
            title: image.title
        };
        return this.firestore.collection('broadcasts').doc(broadcastId).update({
            images: firebase.firestore.FieldValue.arrayRemove(broadcastImage),
        });
    }


    async deleteBroadcastAudio({ broadcastId, audio }) {
        const storageRef = this.storage.ref().child(audio.filepath);
        await storageRef.delete();

        return this.firestore.collection('broadcasts').doc(broadcastId).update({
            audio_filepaths: firebase.firestore.FieldValue.arrayRemove(audio.filepath),
        });
    }

    async uploadBroadcastAudio(broadcastId, { audioFiles }) {
        const filepaths = [];
        const storagePromises = audioFiles.map(audioFile => {
            const filepath = constructBroadcastFilepath(broadcastId, audioFile, BROADCAST_AUDIO_EXPORT_PATH);
            filepaths.push(filepath);
            const storageRef = this.storage.ref().child(filepath);
            return storageRef.put(audioFile);
        });

        await Promise.all(storagePromises);

        return this.firestore.collection('broadcasts').doc(broadcastId).update({
            audio_filepaths: firebase.firestore.FieldValue.arrayUnion(...filepaths),
        });
    }

    async addBroadcastNote(broadcastId, { noteContent }) {
        const noteItem = {
            content: noteContent,
            created_by: this.auth.currentUser.email,
        };

        return this.firestore.collection('broadcasts').doc(broadcastId).update({
            notes: firebase.firestore.FieldValue.arrayUnion(noteItem),
        });
    }
}

export default new Firebase();
