import React, { useState, useEffect } from 'react';

import Loader from './Loader';
import RefreshAccessLinkButton from './RefreshAccessLinkButton';
import BroadcastImages from './BroadcastImages';
import BroadcastAudio from './BroadcastAudio';
import BroadcastNotes from './BroadcastNotes';

import firebase from '../firebase';

import { MODALS } from '../utils/constants';

const FIELDS = {
    title: 'title',
    tracklist: 'tracklist',
};

function BroadcastDetails({ broadcastId, getBroadcastDetails, isAdmin, setModalOpen, selectedBroadcast, setAccessLink, setSelectedImage, setSelectedAudio }) {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [tracklist, setTracklist] = useState('');

    const [fieldsEditing, setFieldsEditing] = useState([]);
    const [fieldSubmitting, setFieldSubmitting] = useState(null);

    useEffect(async () => {
        setLoading(true);
        await getBroadcastDetails(broadcastId);
        setTimeout(() => setLoading(false), 400);
    }, [broadcastId]);

    useEffect(() => {
        if (!selectedBroadcast) return;

        if (!fieldsEditing.includes(FIELDS.title)) setTitle(selectedBroadcast.title);
        if (!fieldsEditing.includes(FIELDS.tracklist)) setTracklist(selectedBroadcast.tracklist);
    }, [selectedBroadcast]);

    function handleChange(e) {
        const { value, name } = e.target;
        const currentValue = selectedBroadcast[name];

        if (value === currentValue) {
            const fields = fieldsEditing.filter(fieldName => fieldName !== name);
            setFieldsEditing(fields);
        } else {
            setFieldsEditing([...fieldsEditing, name]);
        }

        switch(name) {
            case FIELDS.title:
                setTitle(value);
                break;
            case FIELDS.tracklist:
                setTracklist(value);
                break;
        }
    }

    function constructBroadcastUpdate(fieldName) {
        switch(fieldName) {
            case FIELDS.title:
                return { [FIELDS.title]: title };
            case FIELDS.tracklist:
                return { [FIELDS.tracklist]: tracklist };
            default:
                return {};
        }
    }

    async function handleFieldSubmit(e) {
        e.preventDefault();
        const fieldName = FIELDS[e.target.name];
        if (!fieldName) return;

        setFieldSubmitting(fieldName);
        const updateFields = constructBroadcastUpdate(fieldName);
        await firebase.updateBroadcast(broadcastId, updateFields);
        await getBroadcastDetails(broadcastId);
        setTimeout(() => {
            setFieldsEditing(fieldsEditing.filter(
                fieldEditing => fieldEditing !== fieldName
            ));
            setFieldSubmitting(null);
        }, 800);
    }

    if (loading) return (
        <div style={{ padding: '48px 0' }}>
            <Loader />
        </div>
    );

    if (!selectedBroadcast) return (
        <div>
            No broadcast found
        </div>
    );

    return (
        <div>
            <div className="card">
                <div className="card__account-header">
                    <h2 className="card__title">Broadcast details</h2>
                    <div>
                        {isAdmin &&
                            <div className="card__button-container">
                                <button
                                    className="mdl-button mdl-button--raised button-blue"
                                    onClick={() => setModalOpen(MODALS.broadcastAccessLink)}
                                    style={{ minWidth: '150px', float: 'right', marginBottom: '12px' }}>
                                    Create access link
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className="card__results">
                    <div className="card__item-group">
                        <form onSubmit={handleFieldSubmit} name={FIELDS.title}>
                            <label className="mdl-typography--body-1 text-bold" htmlFor={FIELDS.title}>
                                Title:
                            </label>
                            <div className="card__inline-edit">
                                <div>
                                    <input
                                        id={FIELDS.title}
                                        name={FIELDS.title}
                                        className="mdl-textfield__input"
                                        onChange={handleChange}
                                        value={title} />
                                </div>
                                {(fieldsEditing.includes(FIELDS.title) || fieldSubmitting === FIELDS.title) && (
                                    <button disabled={fieldSubmitting === FIELDS.title}
                                        className="mdl-button mdl-button--raised button-blue">
                                        {fieldSubmitting === FIELDS.title ? "Updating..." : "Update" }
                                    </button>
                                )}
                            </div>
                        </form>
                        {isAdmin && (
                            <div>
                                <div className="card__item-group-key text-bold">Linked accounts:</div>
                                <span>
                                    <ul
                                        style={{ display: 'inline-flex' }}
                                        className="card__user-email-list card__array">
                                        {selectedBroadcast.userEmails.map(userEmail => {
                                            return (
                                                <li key={userEmail}>
                                                    {userEmail}
                                                    <RefreshAccessLinkButton
                                                        broadcastId={broadcastId} email={userEmail}
                                                        setAccessLink={setAccessLink} setModalOpen={setModalOpen} />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <BroadcastAudio
                    setModalOpen={setModalOpen}
                    audios={selectedBroadcast.audios}
                    setSelectedAudio={setSelectedAudio} />

                <form onSubmit={handleFieldSubmit} name={FIELDS.tracklist}>
                    <label className="mdl-typography--body-1 text-bold" htmlFor={FIELDS.tracklist}>Tracklist:</label>
                    <div className="card__inline-edit">
                        <textarea onChange={handleChange} value={tracklist} name={FIELDS.tracklist} id={FIELDS.tracklist} placeholder="Artist Name(s) - Track Title" rows="5" cols="33" />
                        {(fieldsEditing.includes(FIELDS.tracklist) || fieldSubmitting === FIELDS.tracklist) && (
                            <button disabled={fieldSubmitting === FIELDS.tracklist}
                                className="mdl-button mdl-button--raised button-blue">
                                {fieldSubmitting === FIELDS.tracklist ? "Updating..." : "Update" }
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <BroadcastImages
                images={selectedBroadcast.images}
                setSelectedImage={setSelectedImage}
                setModalOpen={setModalOpen} />

            <BroadcastNotes
                notes={selectedBroadcast.notes}
                setModalOpen={setModalOpen} />
        </div>
    );
}

export default BroadcastDetails;
